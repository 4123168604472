import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from 'react-scroll';
import Navbar from 'react-bootstrap/Navbar'

const Header = () => {
    const data = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            addressStreet,
            addressCity,
            addressStateZip,
            phone,
            chinesePhone
          }
        }
      }
    `);
    const { addressStreet, addressCity, addressStateZip, phone, chinesePhone } = data.site.siteMetadata;
    return (
    <header id="nav-bar">
        <Navbar bg="light" expand="lg">
          <Navbar.Brand href="#home"><img src={require('../assets/rem_logo.png')} alt="logo"/></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
           <Navbar.Collapse id="basic-navbar-nav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                  <Link activeClass="active" className="nav-link" to="slider" offset={-70} spy={true} smooth={true} duration={1000}>
                    HOME
                  </Link>
              </li>
              <li className="nav-item">
                  <Link activeClass="active" className="nav-link" to="about" offset={-70} spy={true} smooth={true} duration={1000}>
                    ABOUT
                  </Link>
              </li>
              <li className="nav-item">
                  <Link activeClass="active" className="nav-link" to="patients" offset={-70} spy={true} smooth={true} duration={1000}>
                    PATIENTS
                  </Link>
              </li>
              <li className="nav-item">
                  <Link activeClass="active" className="nav-link" to="sleep_disorders" offset={-70} spy={true} smooth={true} duration={1000}>
                    SLEEP DISORDERS
                  </Link>
              </li>
              <li className="nav-item">
                <Link activeClass="active" className="nav-link" to="physicians" offset={-70} spy={true} smooth={true} duration={1000}>
                    PHYSICIANS
                  </Link>
              </li>
              <li className="nav-item">
                  <Link activeClass="active" className="nav-link" to="contact" offset={-70} spy={true} smooth={true} duration={1000}>
                    CONTACT US
                  </Link>
              </li>
              <li className="nav-contact">
                  <h3>{phone}&nbsp;</h3>
                  {chinesePhone ? <h3>中文:{chinesePhone}</h3> : <div/>}
                  <p>{`${addressStreet}`}<br/>
                  {`${addressCity}, ${addressStateZip}`}</p>
                </li>
            </ul>
        </Navbar.Collapse>
      </Navbar>
    </header>
    )
};

export default Header;